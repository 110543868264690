import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import LoadingSpinner from "../components/common/LoadingSpinner/LoadingSpinner";
import withRouter from "../hooks/withRouter";

/* loader component for Suspense*/
/* Used to render a lazy component with react-router */
// const waitFor = (Tag) => (props) => <Tag {...props} />;
const Login = lazy(() => import("../pages/login/login"));
const Principal = lazy(() => import("../pages/principal/principal"));
const Buildings = lazy(() => import("../pages/buildings/buildings"));
const Home = lazy(() => import("../pages/home/home"));
const ProductFamilyList = lazy(() => import("../pages/product-family/product-family-list"));
// const ProductFamily = lazy(() => import("../pages/product-family/product-family"));
const ProductList = lazy(() => import("../pages/product/product-list"));
// const Product = lazy(() => import("../pages/product/product"));
const ReceptionByBuyList = lazy(() => import("../pages/reception-by-buy/reception-by-buy-list"));
const ReceptionByBuy = lazy(() => import("../pages/reception-by-buy/reception-by-buy"));

const RequireAuth = ({ children }) => {
	let auth = (localStorage.getItem("token") || "") !== "";

	return auth ? children : <Navigate replace to="/login" />;
};

const ApplicationRoutes = ({ location }) => {
	location = useLocation();
	const currentKey = location.pathname.split("/")[1] || "/";
	// const currentKey = "/";
	const timeout = { enter: 500, exit: 500 };

	// Animations supported
	//      'rag-fadeIn'
	//      'rag-fadeInRight'
	//      'rag-fadeInLeft'

	const animationName = "rag-fadeIn";

	return (
		<TransitionGroup>
			<CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
				<div>
					<Suspense fallback={<LoadingSpinner />}>
						<Routes>
							<Route index element={<Navigate replace to="/portal/home" />} />

							<Route path="/login" element={<Login />} />
							<Route path="/portal" element={<RequireAuth><Principal /></RequireAuth>}>
								<Route index element={<Home />} />
								<Route path="/portal/home" element={<Home />} />
								<Route path="/portal/buildings" element={<Buildings />} />
								<Route path="/portal/product-family" element={<ProductFamilyList />} />
								{/* <Route path="/portal/product-family/:id" element={<ProductFamily />} /> */}
								<Route path="/portal/product" element={<ProductList />} />
								<Route path="/portal/receptionByBuy" element={<ReceptionByBuyList />} />
								<Route path="/portal/receptionByBuy/:id" element={<ReceptionByBuy />} />								
							</Route>
						</Routes>
					</Suspense>
				</div>
			</CSSTransition>
		</TransitionGroup>
	);
};

export default withRouter(ApplicationRoutes);
